import { GlobalMessage } from '@norges-domstoler/dds-components';
import { globalMessageTypes } from 'app/Models/Constants/MessageTypes';
import WarningBlockData from 'app/Models/WarningBlockData';

interface GlobalMessageWarningProps {
    globalWarning: WarningBlockData;
}

export const GlobalMessageWarning = ({ globalWarning }: GlobalMessageWarningProps) => {
    function isDateAfterToday(date: string) {
        if (!date) {
            return true;
        }
        const dateAsDate = new Date(date);
        const today = new Date();
        return dateAsDate > today;
    }

    return (
        <>
            {globalWarning?.messagesText?.value && isDateAfterToday(globalWarning?.endPublishDate?.value) && (
                <GlobalMessage
                    className="global-messages-container"
                    purpose={globalMessageTypes.types[globalWarning?.messages?.value]}
                    children={
                        <div
                            className="message-content"
                            dangerouslySetInnerHTML={{ __html: globalWarning.messagesText.value }}
                        ></div>
                    }
                />
            )}
        </>
    );
};

export default GlobalMessageWarning;
