import { ContentDelivery } from '@episerver/spa-core';
import { LinksListBlock } from './LinkListBlock';
import Link from '../../../../Shared/Link/link';
import { ArrowRightIcon, Button, ChevronDownIcon, ChevronUpIcon, Icon } from '@norges-domstoler/dds-components';

type MenuItemLinkModel = {
    links: ContentDelivery.LinkListProperty;
    linksHeading: string;
};

type MenuItemProps = {
    childPageVisited: boolean;
    menuItemHeading: string;
    clickOnMenuItem?: () => void;
    clickedOnLink?: () => void;
    href?: string;
    id?: string;
    menuItemList?: MenuItemLinkModel[];
    selected?: boolean;
    isMobile?: boolean;
};

export const MenuItem = ({
    childPageVisited,
    menuItemHeading,
    clickOnMenuItem,
    clickedOnLink,
    href,
    id,
    isMobile = false,
    menuItemList = [],
    selected = false,
    ...props
}: MenuItemProps) => {
    const links = menuItemList
        ?.map((menuItemModel, index) => {
            const { linksHeading, links: linkList } = menuItemModel;
            if (!linksHeading || !linkList?.value?.length) {
                return null;
            }

            return (
                <LinksListBlock
                    key={`${index}-${linksHeading}`}
                    header={linksHeading}
                    listProp={linkList}
                    isMobile={isMobile}
                    isMenuOpen={clickedOnLink}
                    {...props}
                    className="sub-menu-column"
                />
            );
        })
        .filter(Boolean);

    if (!href && !menuItemHeading && links.length === 0) {
        return null;
    }

    const className = `header-menu-item menu-link-button-hover ${selected ? 'selected' : ''} ${href ? 'link-with-icon' : ''} ${
        childPageVisited ? 'child-page-visited' : ''
    }`;

    return (
        <li role="none">
            {href ? (
                <Link
                    onClick={clickedOnLink}
                    aria-haspopup="true"
                    aria-expanded="false"
                    href={href}
                    id={id}
                    className={className}
                >
                    <span>
                        {menuItemHeading}
                        <Icon
                            className="menu-item-button-mobile-icon"
                            color="rgba(53, 71, 84, 1)"
                            icon={ArrowRightIcon}
                            iconSize="small"
                        />
                    </span>
                </Link>
            ) : (
                <Button
                    purpose="tertiary"
                    htmlProps={{ role: 'menuitem', tabIndex: -1 }}
                    aria-haspopup="true"
                    aria-expanded="false"
                    id={id}
                    className={className}
                    onClick={clickOnMenuItem}
                >
                    {menuItemHeading}
                    <Icon
                        color="rgba(53, 71, 84, 1)"
                        icon={selected ? ChevronUpIcon : ChevronDownIcon}
                        iconSize="small"
                        className="menu-item-button-mobile-icon"
                    />
                </Button>
            )}
            {!href && <div className={`nav-menu-group ${selected ? 'selected' : ''}`}>{links}</div>}
        </li>
    );
};

export default MenuItem;
