import { Grid, GridChild, Search, Typography } from '@norges-domstoler/dds-components';
import { useEpiserver } from '@episerver/spa-core';
import { useLocation, useNavigate } from 'react-router';
import store, { AppActionTypes } from '../../../../../store';
import { MutableRefObject, MouseEvent, KeyboardEvent, useRef } from 'react';
import { General, Header, useTranslation } from 'app/hooks/useTranslations';

type SearchBoxHeaderProps = {
    setIsOpenSearch: (isOpen: boolean) => void;
    isOpenSearch: boolean;
    searchPath: string;
    language: string;
    path: string;
    searchFieldRef: MutableRefObject<HTMLInputElement>;
};

export const SearchBoxHeader = ({
    setIsOpenSearch,
    isOpenSearch,
    searchPath,
    language,
    searchFieldRef,
}: SearchBoxHeaderProps) => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const ctx = useEpiserver();

    const buttonRef = useRef<HTMLButtonElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    const pathName = location.pathname;
    const searchLabel = t(`${General}.Search`, language);
    const searchQuestionText = t(`${Header}.SearchQuestion`, language);

    const getSearchUrl = () => {
        const searchValue = searchFieldRef.current?.value;
        if (searchValue && searchPath) {
            const searchUrl = new URL(searchPath).pathname + '?search=' + searchValue;
            return searchUrl;
        }
        return null;
    };

    const redirectToSearchPage = (searchUrl: string) => {
        const spaSearchRoute = ctx.getSpaRoute(searchUrl);
        const currentPath = pathName.split('?')[0];
        const newPath = spaSearchRoute.split('?')[0];

        navigate(spaSearchRoute);
        if (currentPath === newPath) window.location.reload();
    };

    const handleSearch = (e: MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLInputElement>) => {
        if ('key' in e && e.key !== 'Enter') return;

        const searchUrl = getSearchUrl();
        if (searchUrl) {
            setIsOpenSearch(false);
            store.dispatch({ type: AppActionTypes.TOGGLE_MENU_MOBILE, payload: false });
            redirectToSearchPage(searchUrl);
        } else {
            e.preventDefault();
        }
    };

    const handleBlur = (e: React.FocusEvent) => {
        const relatedTarget = e.relatedTarget as HTMLElement;
        const isOutsideContainer = containerRef.current && !containerRef.current.contains(relatedTarget);

        if (isOutsideContainer && !e.currentTarget.contains(relatedTarget)) {
            setIsOpenSearch(false);
        }
    };

    return (
        <Grid
            as="form"
            className="container"
            htmlProps={{
                role: 'search',
                onSubmit: (e) => e.preventDefault(),
                onFocus: () => setIsOpenSearch(true),
            }}
        >
            <GridChild
                columnsOccupied={{
                    xs: '1/-1',
                    sm: '1/8',
                    md: '1/6',
                    lg: '1/6',
                    xl: '1/6',
                }}
            >
                <Typography
                    className="startpage-searchbar-header"
                    typographyType="headingMedium"
                    as="label"
                    withMargins
                    // @ts-expect-error: "for" finnes ikke i htmlProps-definisjonen
                    htmlProps={{ htmlFor: 'header-search-box' }}
                >
                    {searchQuestionText}
                </Typography>
                <div ref={containerRef}>
                    <Search
                        id="header-search-box"
                        tabIndex={isOpenSearch ? 0 : -1}
                        type="search"
                        maxLength={255}
                        componentSize="medium"
                        onKeyDown={handleSearch}
                        ref={searchFieldRef}
                        className="search-input-field"
                        onFocus={() => setIsOpenSearch(true)}
                        buttonProps={{
                            label: searchLabel,
                            'aria-label': searchLabel,
                            ref: buttonRef,
                            onClick: handleSearch,
                            tabIndex: isOpenSearch ? 0 : -1,
                            onFocus: () => setIsOpenSearch(true),
                            onBlur: handleBlur,
                        }}
                    />
                </div>
            </GridChild>
        </Grid>
    );
};

export default SearchBoxHeader;
