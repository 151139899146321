import {
    Typography,
    Icon,
    LanguageIcon,
    CloseIcon,
    MenuIcon,
    ArrowLeftIcon,
    Button,
    useScreenSize,
    ScreenSize,
} from '@norges-domstoler/dds-components';
import { useOnClickOutside } from '@norges-domstoler/dds-components';
import { State, useEpiserver } from '@episerver/spa-core';
import { useState, useRef } from 'react';
import { connect } from 'react-redux';
import TopMenuItems, { TU_HEADER_ID } from './TopMenuItems';
import SkipToContentWrapper from '../Shared/SkipToContentWrapper';
import LanguageSelector from '../Shared/LanguageSelector/LanguageSelector';
import TULogo from './TULogo';
import { HeaderProps } from '../Shared/types';
import { languageNames } from 'app/Models/Constants/LanguageTypes';
import { General, Header as LocalizationHeader, useTranslation } from 'app/hooks/useTranslations';
import './HeaderTilsynsutvalget.scss';

export const TU_MENU_BUTTON_ID = 'menu-button';

export enum TUMenuType {
    Language = 'language',
    Hamburger = 'hamburger',
    ClosedMenu = 'closedMenu',
}

export const Header = ({ settings, ...props }: HeaderProps) => {
    const { t } = useTranslation();
    const ctx = useEpiserver();
    const screenSize = useScreenSize();
    const currentLanguage = settings?.language?.name;
    const useLanguageSelector = settings?.useLanguageSelector?.value;

    const [activeMenu, setActiveMenu] = useState<TUMenuType>(TUMenuType.ClosedMenu);

    const languageSelectorRef = useRef<HTMLLIElement>(null);
    const menuRef = useRef<HTMLLIElement>(null);

    useOnClickOutside(languageSelectorRef.current, () => {
        if (activeMenu === TUMenuType.Language) {
            setActiveMenu(TUMenuType.ClosedMenu);
        }
    });

    useOnClickOutside(menuRef.current, () => {
        if (activeMenu === TUMenuType.Hamburger) {
            setActiveMenu(TUMenuType.ClosedMenu);
        }
    });

    const toggleMenu = (menuType: TUMenuType) => {
        setActiveMenu((prev) => (prev === menuType ? TUMenuType.ClosedMenu : menuType));
    };

    const isMenuOpen = (menuType: TUMenuType) => activeMenu === menuType;

    const handleLanguageSelectorToggle = (isOpen: boolean) => {
        setActiveMenu(isOpen ? TUMenuType.Language : TUMenuType.ClosedMenu);
    };

    const isMenuButtonOpen = () => {
        if (screenSize <= ScreenSize.Small) {
            return activeMenu !== TUMenuType.ClosedMenu;
        } else {
            return activeMenu === TUMenuType.Hamburger;
        }
    };

    const handleLanguageButtonClick = () => {
        setActiveMenu(TUMenuType.Language);
    };

    return (
        <header id={TU_HEADER_ID}>
            <div className="header-top">
                <TULogo language={currentLanguage} />
                <nav>
                    <ul>
                        {useLanguageSelector && (
                            <li ref={languageSelectorRef}>
                                <Button
                                    id="languageButton"
                                    purpose="tertiary"
                                    className="header-buttons"
                                    aria-expanded={isMenuOpen(TUMenuType.Language)}
                                    aria-controls="header-language-box-wrapper"
                                    onClick={() => toggleMenu(TUMenuType.Language)}
                                    onFocus={() => setActiveMenu(TUMenuType.ClosedMenu)}
                                    icon={isMenuOpen(TUMenuType.Language) ? CloseIcon : LanguageIcon}
                                    iconPosition="left"
                                >
                                    {isMenuOpen(TUMenuType.Language)
                                        ? t(`${General}.Close`, currentLanguage)
                                        : languageNames[ctx.Language]}
                                </Button>
                                <div
                                    className={`nav-menu-group language ${
                                        isMenuOpen(TUMenuType.Language) ? 'display' : ''
                                    }`}
                                >
                                    <div className="language-margin">
                                        <div className="menu-icon-button language-selector-button">
                                            <button onClick={() => setActiveMenu(TUMenuType.Hamburger)}>
                                                <Icon icon={ArrowLeftIcon} iconSize="small" />
                                                <Typography typographyType="bodyMedium">
                                                    {t(`${General}.Back`, currentLanguage)}
                                                </Typography>
                                            </button>
                                        </div>
                                        <LanguageSelector
                                            settings={settings}
                                            isOpenLanguageSelector={isMenuOpen(TUMenuType.Language)}
                                            setIsOpenLanguageSelector={handleLanguageSelectorToggle}
                                            clickedOnLink={() => setActiveMenu(TUMenuType.ClosedMenu)}
                                            {...props}
                                        />
                                    </div>
                                </div>
                            </li>
                        )}

                        <li ref={menuRef}>
                            <Button
                                id={TU_MENU_BUTTON_ID}
                                purpose="tertiary"
                                aria-controls="menu"
                                className="header-buttons"
                                aria-expanded={isMenuOpen(TUMenuType.Hamburger)}
                                onClick={() => toggleMenu(TUMenuType.Hamburger)}
                                onFocus={() => setActiveMenu(TUMenuType.ClosedMenu)}
                                icon={isMenuButtonOpen() ? CloseIcon : MenuIcon}
                                iconPosition="left"
                            >
                                {isMenuButtonOpen()
                                    ? t(`${General}.Close`, currentLanguage)
                                    : t(`${LocalizationHeader}.MainMenu`, currentLanguage)}
                            </Button>
                            <div className={`nav-menu-group ${isMenuOpen(TUMenuType.Hamburger) ? 'display' : ''}`}>
                                <TopMenuItems
                                    settings={settings}
                                    handleLanguageButtonClick={handleLanguageButtonClick}
                                    handleLinkClick={() => setActiveMenu(TUMenuType.ClosedMenu)}
                                />
                            </div>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
    );
};

export const ConnectedHeader = connect((state: State.CmsAppState, otherState: any) => ({
    ...state.OptiContentCloud,
    textNO: otherState.textNO,
}))(Header);

export const HeaderTilsynsutvalget = (props: HeaderProps) => {
    const ctx = useEpiserver();
    if (ctx.isServerSideRendering()) {
        return <HeaderTilsynsutvalget {...props} />;
    }
    return <ConnectedHeader {...props} />;
};

export default HeaderTilsynsutvalget;
