import React from 'react';

type DropdownWrapperProps = {
    id: string;
    isActive: boolean;
    children: React.ReactNode;
};

const DropdownWrapper = ({ id, isActive, children }: DropdownWrapperProps) => {
    return (
        <div id={id} className={`dropdown-menu ${isActive ? '' : 'custom-visually-hidden'}`}>
            {children}
        </div>
    );
};

export default DropdownWrapper;
