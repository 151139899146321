import LayoutSettings from 'app/Models/Content/LayoutSettingsData';
import { SiteType } from '../../../Models/Enums/SiteType';
import { memo } from 'react';
import HeaderTilsynsutvalget from './TU/HeaderTilsynsutvalget';
import HeaderDomstolno from './Domstolno/HeaderDomstolno';
import SkipToContentWrapper from './Shared/SkipToContentWrapper';

interface HeaderProps {
    settings: LayoutSettings;
    pathName: string;
}

const Header = ({ settings, pathName }: HeaderProps) => {
    const siteTypeValue = settings?.siteType?.value;

    if (siteTypeValue === SiteType.Tilsynsutvalget) {
        return <HeaderTilsynsutvalget settings={settings} path={pathName} />;
    }

    return <HeaderDomstolno settings={settings} path={pathName} />;
};

const areEqual = (prevProps: HeaderProps, nextProps: HeaderProps) => {
    const prevSettings = prevProps.settings;
    const nextSettings = nextProps.settings;

    return (
        prevSettings?.siteType?.value === nextSettings?.siteType?.value &&
        prevSettings?.language?.name === nextSettings?.language?.name &&
        prevSettings?.changed === nextSettings?.changed
    );
};

const MemoizedHeader = memo(Header, areEqual);

const HeaderWrapper = ({ settings, pathName }: HeaderProps) => {
    return (
        <>
            <SkipToContentWrapper />
            <MemoizedHeader settings={settings} pathName={pathName} />
        </>
    );
};

export default HeaderWrapper;
