import { State, useEpiserver } from '@episerver/spa-core';
import { connect } from 'react-redux';
import HoyesterettLayout, { CurrentContent } from '../Shared/Old/Layout/HoyesterettLayout';
import { Helmet } from 'react-helmet-async';
import { RootLayoutProps } from './RootLayoutProps';
import useLayoutSettings, { LayoutSettingsContext } from './Hooks/useLayoutSettings';
import { LocalizationProvider } from './Hooks/useLocalizationContext';
import { isHoyesterettPage, shouldRenderEmptyLayout } from './utils';
import { useCurrentContent } from './Hooks/useCurrentContent';
import EmptyLayout from './SiteLayouts/EmptyLayout';
import SSRLayout from './SiteLayouts/SSRLayout';
import DefaultLayout from './SiteLayouts/DefaultLayout';
import { ThemeProvider } from '@norges-domstoler/dds-components';

const RootLayout = ({ ...props }: RootLayoutProps) => {
    const ctx = useEpiserver();
    const { currentLanguage, children } = props;

    const { currentContent, pathName } = useCurrentContent();
    const { layoutSettings } = useLayoutSettings(currentLanguage);

    if (ctx.isServerSideRendering()) {
        return <SSRLayout lang={currentLanguage} children={children} />;
    }

    if (shouldRenderEmptyLayout(currentContent, ctx)) {
        return <EmptyLayout />;
    }

    if (isHoyesterettPage(currentContent)) {
        const loadedCurrentContent = currentContent as CurrentContent;

        return (
            <HelmetAndLocalizationWrapper>
                <HoyesterettLayout
                    {...props}
                    currentContent={loadedCurrentContent}
                    layoutSettings={layoutSettings?.feedbackDisclaimer}
                    pathname={pathName}
                />
            </HelmetAndLocalizationWrapper>
        );
    }

    return (
        <HelmetAndLocalizationWrapper>
            <LayoutSettingsContext.Provider value={layoutSettings}>
                <ThemeProvider theme="public">
                    <DefaultLayout {...props} pathname={pathName} context={ctx} />
                </ThemeProvider>
            </LayoutSettingsContext.Provider>
        </HelmetAndLocalizationWrapper>
    );
};

const HelmetAndLocalizationWrapper = ({ children }: { children: React.ReactNode }) => (
    <>
        <Helmet>
            <meta property="og:url" content={window.location.href} />
        </Helmet>
        <LocalizationProvider>{children}</LocalizationProvider>
    </>
);

export const ConnectedRootLayout = connect((state: State.CmsAppState, ownProps: RootLayoutProps) => {
    const propsFromState = state.OptiContentCloud || {};
    return { ...ownProps, ...propsFromState };
})(RootLayout);

export default ConnectedRootLayout;
