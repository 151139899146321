import { useEpiserver } from '@episerver/spa-core';
import { SkipToContent } from '@norges-domstoler/dds-components';

export const SkipToContentWrapper = () => {
    const ctx = useEpiserver();

    if (ctx.isServerSideRendering()) {
        return null;
    }

    const scrollToMainAndDispatchEvent = () => {
        document.getElementById('main')?.scrollIntoView({ behavior: 'smooth' });
        document.body.dispatchEvent(new Event('mainEvent'));
    };

    const handleClick = () => scrollToMainAndDispatchEvent();

    const handleKeyDown = (e: React.KeyboardEvent<HTMLAnchorElement>) => {
        if (e.key === 'Enter') {
            scrollToMainAndDispatchEvent();
        }
    };

    return (
        <SkipToContent
            href="#main"
            htmlProps={{
                onClick: handleClick,
                onKeyDown: (e) => handleKeyDown(e),
            }}
        />
    );
};

export default SkipToContentWrapper;
