import { Helmet } from 'react-helmet-async';

type SSRLayout = {
    children?: React.ReactNode;
    lang: string;
};
export const SSRLayout = ({ children, lang }: SSRLayout) => {
    return (
        <>
            <div className="mosey-layout">
                <Helmet htmlAttributes={{ lang: lang }}>
                    <meta property="og:type" content="website" />
                    <meta property="og:locale" content={lang ?? 'no'} />
                </Helmet>

                <main id="main" style={{ visibility: 'hidden' }}>
                    {children}
                </main>
            </div>
        </>
    );
};

export default SSRLayout;
