import { Typography, Icon, LanguageIcon } from '@norges-domstoler/dds-components';
import { useRef } from 'react';
import MenuItem from './TilsynsutvalgetMenuItem';
import { LayoutSettingsType } from '../Shared/types';
import { Header, useTranslation } from 'app/hooks/useTranslations';

export const TU_HEADER_ID = 'tu-header';

export type TopMenuItemsProps = {
    handleLinkClick: () => void;
    handleLanguageButtonClick: () => void;
} & LayoutSettingsType;

export const TopMenuItems = ({ settings, handleLinkClick, handleLanguageButtonClick }: TopMenuItemsProps) => {
    const { t } = useTranslation();
    const containerRef = useRef<HTMLDivElement>(null);

    const {
        useLanguageSelector,
        language,
        beforeMeetingTheCourtsLinks,
        summonedForTrialLinks,
        publicAndInsightsLinks,
        themeLinks,
        servicesLinks,
    } = settings;

    const headerLanguageText = t(`${Header}.Language`, language?.name);

    const handleBlur = (e: React.FocusEvent<HTMLDivElement>) => {
        const relatedTarget = e.relatedTarget as HTMLElement;
        const isTabbing = relatedTarget === null;

        if (!isTabbing) {
            const relatedTarget = e.relatedTarget as HTMLElement;
            const isOutsideContainer = containerRef.current && !containerRef.current.contains(relatedTarget);

            if (isOutsideContainer) {
                handleLinkClick();
            }
        }
    };

    return (
        <div className="menu-container container" id="menu" role="menu" ref={containerRef} onBlur={handleBlur}>
            {useLanguageSelector?.value && (
                <div className="menu-icon-button language-selector-button">
                    <button onClick={handleLanguageButtonClick}>
                        <Icon icon={LanguageIcon} iconSize="medium" />
                        <Typography typographyType="bodyMedium">{headerLanguageText}</Typography>
                    </button>
                </div>
            )}
            <MenuItem links={beforeMeetingTheCourtsLinks} handleOnClick={handleLinkClick} />
            <MenuItem links={summonedForTrialLinks} handleOnClick={handleLinkClick} />
            <MenuItem links={publicAndInsightsLinks} handleOnClick={handleLinkClick} />
            <MenuItem links={themeLinks} handleOnClick={handleLinkClick} />
            <MenuItem links={servicesLinks} handleOnClick={handleLinkClick} />
        </div>
    );
};

export default TopMenuItems;
