import { createContext, useContext, useEffect, useState } from 'react';
import LayoutSettings from 'app/Models/Content/LayoutSettingsData';
import store, { AppActionTypes } from '../../../store';
import { ContentDelivery, Taxonomy, useIContentRepository } from '@episerver/spa-core';
import { useSettings } from '../../../packages/foundation-settings';
import axios from 'axios';
import { JsonToCamel } from 'app/utils/convertObjectTocamelCase';

export enum DomstolMenuId {
    FinnDomstolMenuId = 'finn-domstol',
    IRettenMenuId = 'i-retten',
    TjenesterOgSkjemaMenuId = 'tjenester-og-skjema',
    OmDomstoleneMenuId = 'om-domstolene',
    Closed = '',
}

export type DomstolMenuIdType =
    | typeof DomstolMenuId.FinnDomstolMenuId
    | typeof DomstolMenuId.IRettenMenuId
    | typeof DomstolMenuId.TjenesterOgSkjemaMenuId
    | typeof DomstolMenuId.OmDomstoleneMenuId
    | typeof DomstolMenuId.Closed;

function updateStore(layoutSettings: any) {
    const tempMenuIds: string[][] = [];

    filterLinkIdFromLinkProperty(layoutSettings?.findCourtFromHeader, tempMenuIds, DomstolMenuId.FinnDomstolMenuId);
    filterLinkIdsFromLinkListProperty(
        layoutSettings?.beforeMeetingTheCourtsLinks,
        tempMenuIds,
        DomstolMenuId.IRettenMenuId
    );
    filterLinkIdsFromLinkListProperty(layoutSettings?.summonedForTrialLinks, tempMenuIds, DomstolMenuId.IRettenMenuId);
    filterLinkIdsFromLinkListProperty(layoutSettings?.publicAndInsightsLinks, tempMenuIds, DomstolMenuId.IRettenMenuId);
    filterLinkIdsFromLinkListProperty(layoutSettings?.themeLinks, tempMenuIds, DomstolMenuId.TjenesterOgSkjemaMenuId);
    filterLinkIdsFromLinkListProperty(
        layoutSettings?.servicesLinks,
        tempMenuIds,
        DomstolMenuId.TjenesterOgSkjemaMenuId
    );
    filterLinkIdsFromLinkListProperty(layoutSettings?.logInLinks, tempMenuIds, DomstolMenuId.TjenesterOgSkjemaMenuId);
    filterLinkIdsFromLinkListProperty(
        layoutSettings?.courtsInNorwayLinks,
        tempMenuIds,
        DomstolMenuId.OmDomstoleneMenuId
    );
    filterLinkIdsFromLinkListProperty(layoutSettings?.judgesLinks, tempMenuIds, DomstolMenuId.OmDomstoleneMenuId);
    filterLinkIdsFromLinkListProperty(layoutSettings?.aboutUsLinks, tempMenuIds, DomstolMenuId.OmDomstoleneMenuId);

    store.dispatch({ type: AppActionTypes.SET_MENU_IDS, payload: tempMenuIds });
    store.dispatch({ type: AppActionTypes.SET_MENU_WITH_FOCUS, payload: '' });
}

function filterLinkIdsFromLinkListProperty(
    links: Taxonomy.Property.LinkListProperty,
    tempMenuIds: string[][],
    subMenuName: string
) {
    if (links != null && links?.value?.length > 0) {
        links.value.forEach((link) => {
            if (link?.contentLink?.guidValue) tempMenuIds.push([link.contentLink.guidValue, subMenuName]);
        });
    }
}

function filterLinkIdFromLinkProperty(
    link: ContentDelivery.Property<any>,
    tempMenuIds: string[][],
    subMenuName: string
) {
    if (link?.value?.contentLink?.guidValue) {
        tempMenuIds.push([link?.value?.contentLink?.guidValue, subMenuName]);
    }
}

const LayoutSettingsContainer = 'LayoutSettings';

const useLayoutSettings = (currentLanguage: string) => {
    const [layoutSettings, setLayoutSettings] = useState<ExtendedLayoutSettings | undefined>(undefined);
    const [loading, setLoading] = useState(true);
    const repo = useIContentRepository();
    const settingsService = useSettings();

    useEffect(() => {
        const fetchLayoutSettings = async () => {
            try {
                const website = await repo.getCurrentWebsite();
                const site = website || (await axios.get('/api/episerver/v3.0/site')).data[0];

                const settingsData = await settingsService.getContainer<LayoutSettings>(LayoutSettingsContainer, site);
                const camelizedSettings = JsonToCamel(settingsData) as ExtendedLayoutSettings;

                camelizedSettings.menuIds = setLayoutSettings(camelizedSettings);
                updateStore(camelizedSettings);
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        };

        fetchLayoutSettings();
    }, [currentLanguage, repo, settingsService]);

    return { layoutSettings, loading };
};

export default useLayoutSettings;

export type ExtendedLayoutSettings = {
    menuIds: any;
    menuWithFocus: any;
    banner: any;
} & LayoutSettings;

export const LayoutSettingsContext = createContext<ExtendedLayoutSettings>(undefined);

export const useLayoutSettingsContext = () => useContext(LayoutSettingsContext);

export const LocalizationProvider = ({
    children,
    layoutsettings,
}: {
    children: React.ReactNode;
    layoutsettings: ExtendedLayoutSettings;
}) => <LayoutSettingsContext.Provider value={layoutsettings}>{children}</LayoutSettingsContext.Provider>;
