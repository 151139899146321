import { LanguageTag } from 'app/Models/Constants/LanguageTypes';
import Link from '../../../../Components/Shared/Link/link';
import { TU, useTranslation } from 'app/hooks/useTranslations';

type LogoProps = {
    language: string;
};

export const TULogo = ({ language }: LogoProps) => {
    const { t } = useTranslation();

    const logoAltText = t(`${TU}.LogoAltTekst`, language);

    const isNorwegian = language === LanguageTag.NORWEGIAN;
    const logoFileName = isNorwegian
        ? 'tilsynsutvalget_liggende_sort_farget_riksvopen_no.png'
        : 'tilsynsutvalget_liggende_sort_farget_riksvopen_en.jpg';

    const riksvopenLogoPath = `/StaticContent/logo/${logoFileName}`;

    return (
        <div className="logo">
            <Link href="/">
                <img alt={logoAltText} height="80" src={riksvopenLogoPath} />
            </Link>
        </div>
    );
};

export default TULogo;
