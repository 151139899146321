import { Typography } from '@norges-domstoler/dds-components';
import { ContentDelivery } from '@episerver/spa-core';
import { IsRecycleBinLink } from '../../../../Shared/Utils';
import LinkListLink from './LinkListLink';

type LinksListBlockProps = {
    listProp: ContentDelivery.LinkListProperty;
    isMobile: boolean;
    isMenuOpen?: (isOpen: boolean) => void;
    className?: string;
    header?: string;
};

export const LinksListBlock = ({ listProp, isMobile, isMenuOpen, className, header }: LinksListBlockProps) => {
    const listOfLinks = listProp?.value;
    if (!listOfLinks || listOfLinks.length < 1) {
        return null;
    }
    const links: JSX.Element[] = listOfLinks.map((linkListProp, index) => {
        if (!IsRecycleBinLink(linkListProp)) {
            return (
                <LinkListLink
                    isMobile={isMobile}
                    key={`${index}${linkListProp.contentLink?.id}`}
                    isMenuOpen={isMenuOpen}
                    link={linkListProp}
                />
            );
        }
    });

    if (links.length === 0) {
        return null;
    }

    return (
        <div className={className}>
            <Typography typographyType="headingXsmall" as="h2" withMargins>
                {header}
            </Typography>
            <ul role="menu" aria-label={header}>
                {links}
            </ul>
        </div>
    );
};

export default LinksListBlock;
