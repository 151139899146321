import MenuItem from '../../Shared/MenuItem/MenuItem';
import { DomstolMenuId, DomstolMenuIdType } from '../../../Hooks/useLayoutSettings';
import { LayoutSettingsType } from '../../Shared/types';
import { MenuType } from './NavigationSection';
import { useEffect, useState } from 'react';
import store from 'app/store';

export const menuBarId = 'menubar-domstol';

type MenuListProps = LayoutSettingsType & {
    isOpenMobileMenu: boolean;
    menuItemsRef: any;
    activeMenu: string | undefined;
    handleClickOnLink: () => void;
    anchorClickHandler: (id: string) => void;
};
export const MenuList = ({
    isOpenMobileMenu,
    menuItemsRef,
    settings,
    activeMenu,
    handleClickOnLink,
    anchorClickHandler,
    ...props
}: MenuListProps) => {
    const [childPageVisited, setChildPageVisited] = useState<DomstolMenuIdType>(DomstolMenuId.Closed);

    const inCourtMenuItems = [
        {
            linksHeading: settings?.beforeMeetingTheCourtsHeading?.value,
            links: settings?.beforeMeetingTheCourtsLinks,
        },
        {
            linksHeading: settings?.summonedForTrialHeading?.value,
            links: settings?.summonedForTrialLinks,
        },
        {
            linksHeading: settings?.publicAndInsightsHeading?.value,
            links: settings?.publicAndInsightsLinks,
        },
    ];

    const servicesAndFormsMenuItems = [
        {
            linksHeading: settings?.themeHeading?.value,
            links: settings?.themeLinks,
        },
        {
            linksHeading: settings?.servicesHeading?.value,
            links: settings?.servicesLinks,
        },
        {
            linksHeading: settings?.logInHeading?.value,
            links: settings?.logInLinks,
        },
    ];

    const aboutMenuItems = [
        {
            linksHeading: settings?.courtsInNorwayHeading?.value,
            links: settings?.courtsInNorwayLinks,
        },
        {
            linksHeading: settings?.judgesHeading?.value,
            links: settings?.judgesLinks,
        },
        {
            linksHeading: settings?.aboutUsHeading?.value,
            links: settings?.aboutUsLinks,
        },
    ];

    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            setChildPageVisited(store.getState().menuWithFocus);
        });

        return () => {
            unsubscribe();
        };
    }, []);

    const finalChildPageVisited = activeMenu === MenuType.ClosedMenu ? childPageVisited : activeMenu;

    return (
        <ul
            id={menuBarId}
            role="menubar"
            className={`menu-items-container ${settings?.language?.name} ${isOpenMobileMenu ? 'open-mobile' : ''}`}
            ref={menuItemsRef}
        >
            <MenuItem
                {...props}
                childPageVisited={finalChildPageVisited === DomstolMenuId.FinnDomstolMenuId}
                menuItemHeading={settings?.findCourtFromHeader?.value?.text}
                clickedOnLink={handleClickOnLink}
                href={
                    settings?.findCourtFromHeader?.value?.contentLink?.url ?? settings?.findCourtFromHeader?.value?.href
                }
            />

            <MenuItem
                {...props}
                isMobile={isOpenMobileMenu}
                clickedOnLink={handleClickOnLink}
                menuItemHeading={settings?.inCourtHeading?.value}
                clickOnMenuItem={() => anchorClickHandler(DomstolMenuId.IRettenMenuId)}
                childPageVisited={finalChildPageVisited === DomstolMenuId.IRettenMenuId}
                selected={activeMenu === DomstolMenuId.IRettenMenuId}
                id={DomstolMenuId.IRettenMenuId}
                menuItemList={inCourtMenuItems}
            />

            <MenuItem
                {...props}
                isMobile={isOpenMobileMenu}
                clickedOnLink={handleClickOnLink}
                menuItemHeading={settings?.servicesAndFormsHeading?.value}
                clickOnMenuItem={() => anchorClickHandler(DomstolMenuId.TjenesterOgSkjemaMenuId)}
                childPageVisited={finalChildPageVisited === DomstolMenuId.TjenesterOgSkjemaMenuId}
                selected={activeMenu === DomstolMenuId.TjenesterOgSkjemaMenuId}
                id={DomstolMenuId.TjenesterOgSkjemaMenuId}
                menuItemList={servicesAndFormsMenuItems}
            />

            <MenuItem
                {...props}
                isMobile={isOpenMobileMenu}
                clickedOnLink={handleClickOnLink}
                menuItemHeading={settings?.aboutHeading?.value}
                clickOnMenuItem={() => anchorClickHandler(DomstolMenuId.OmDomstoleneMenuId)}
                childPageVisited={finalChildPageVisited === DomstolMenuId.OmDomstoleneMenuId}
                selected={activeMenu === DomstolMenuId.OmDomstoleneMenuId}
                id={DomstolMenuId.OmDomstoleneMenuId}
                menuItemList={aboutMenuItems}
            />
        </ul>
    );
};

export default MenuList;
