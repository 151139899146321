import { ContentDelivery, useEpiserver } from '@episerver/spa-core';
import Link from '../../../../Shared/Link/link';
import { Typography, Icon, ArrowRightIcon, OpenExternalIcon } from '@norges-domstoler/dds-components';

type LinkListLinkProps = {
    link: ContentDelivery.LinkProperty;
    isMobile?: boolean;
    isMenuOpen?: (isOpen: boolean) => void;
};

export const LinkListLink = ({ link, isMobile, isMenuOpen }: LinkListLinkProps) => {
    const url = new URL(link.href);
    const ctx = useEpiserver();
    let offDomain: boolean = true;
    try {
        if (window.location.protocol !== url.protocol && window.location.host === url.host)
            url.protocol = window.location.protocol;
        offDomain = window.location.host !== url.host;
    } catch (e) {
        // Ignored on purpose
    }
    const linkProps: React.AnchorHTMLAttributes<HTMLAnchorElement> = {
        title: link.title,
        target: link.target,
        className: 'nav-item',
    };
    if (offDomain) {
        linkProps.rel = 'noreferrer';
    }

    return (
        <li>
            <Link
                className="submenu-link menu-link-button-hover"
                href={url.href}
                onClick={() => {
                    isMenuOpen(true);
                }}
            >
                <Typography
                    typographyType={isMobile ? 'bodySmall' : 'bodyMedium'}
                    {...linkProps}
                    className="link-container"
                    as="div"
                >
                    <span>{link.text}</span>
                </Typography>
                {!ctx.isServerSideRendering() && (
                    <Icon
                        color="rgba(53, 71, 84, 1)"
                        className="icon-right"
                        icon={link.target === '_blank' ? OpenExternalIcon : ArrowRightIcon}
                        iconSize="small"
                    />
                )}
            </Link>
        </li>
    );
};

export default LinkListLink;
