import AboutTheWebsiteBlockData from 'app/Models/Content/AboutTheWebsiteBlockData';
import './FooterTilsynsutvalget.scss';
import { ContentDelivery } from '@episerver/spa-core';
import { Link } from '@norges-domstoler/dds-components';
import { getLinkTitleFromLinkProperty, getLinkUrlFromLinkProperty } from '../../../Components/Shared/Utils';
import HyphenateTitleElement from '../../../Components/Shared/renderTitle';
import { minWordLengthFooterText } from '../../../Models/Constants/SiteSettings';
import { Footer, useTranslation } from 'app/hooks/useTranslations';

interface FooterLinkProps {
    url: ContentDelivery.LinkProperty;
}

const FooterLink = ({ url }: FooterLinkProps) => {
    if (!url?.href) return null;

    return (
        <Link className="footer-url-color-and-margin" href={getLinkUrlFromLinkProperty(url)} withMargins>
            <HyphenateTitleElement minWordLength={minWordLengthFooterText} title={getLinkTitleFromLinkProperty(url)} />
        </Link>
    );
};

interface FooterProps {
    aboutWebsite: AboutTheWebsiteBlockData;
    language: string;
}

export const FooterTilsynsutvalget = ({ aboutWebsite, language }: FooterProps) => {
    const { t } = useTranslation();

    const ariaLabel = t(`${Footer}.Label`, language);

    return (
        <footer className="footer-max-width-section">
            <nav id="footer-nav" className="row footer-max-width container" aria-label={ariaLabel}>
                <div className="footer-links-container">
                    <div className="footer-links">
                        <h2 className="visually-hidden">{ariaLabel}</h2>
                        <FooterLink url={aboutWebsite?.availabilityUrl?.value} />
                        <FooterLink url={aboutWebsite?.privacyUrl?.value} />
                    </div>
                </div>
            </nav>
        </footer>
    );
};

export default FooterTilsynsutvalget;
