import { SkipToContentWrapper } from '../Shared/SkipToContentWrapper';
import { useEpiserver } from '@episerver/spa-core';
import { State } from '@episerver/spa-core';
import { connect } from 'react-redux';
import Logo from './Logo/Logo';
import { HeaderProps } from '../Shared/types';
import './HeaderDomstolno.scss';
import NavigationSection from './Navigation/NavigationSection';

export const HeaderComponent = (props: HeaderProps) => {
    const currentLanguage = props.currentLanguage;

    return (
        <header key="mosey-header" className="domstolno-header">
            <div className="header-container">
                <Logo language={currentLanguage} />
                <NavigationSection {...props} />
            </div>
        </header>
    );
};
export const ConnectedHeader = connect((state: State.CmsAppState) => state.OptiContentCloud || {})(HeaderComponent);

export const HeaderDomstolno = (props: HeaderProps) => {
    const ctx = useEpiserver();
    if (ctx.isServerSideRendering()) return <HeaderComponent {...props} />;
    return <ConnectedHeader {...props} />;
};

export default HeaderDomstolno;
