import { useIContentRepository, Taxonomy, State, useEpiserver } from '@episerver/spa-core';
import { Icon, CheckIcon } from '@norges-domstoler/dds-components';
import Website from '@episerver/spa-core/dist/Models/Website';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { connect } from 'react-redux';
import axios from 'axios';
import { languageNames, LanguageTag } from '../../../../../Models/Constants/LanguageTypes';
import { HeaderProps } from '../types';
import './LanguageSelector.scss';
import { Link } from 'react-router-dom';

export type LanguageSelectorProps = HeaderProps & {
    isOpenLanguageSelector: boolean;
    setIsOpenLanguageSelector: (isOpen: boolean) => void;
    clickedOnLink: () => void;
    isMobile?: boolean;
    languageFieldRef?: MutableRefObject<HTMLAnchorElement>;
};

export const DefaultLanguageSelector = ({
    dispatch,
    isMobile = false,
    clickedOnLink,
    setIsOpenLanguageSelector,
    isOpenLanguageSelector,
    currentLanguage,
    languageFieldRef = undefined,
    ...props
}: LanguageSelectorProps) => {
    const repo = useIContentRepository();
    const navigate = useNavigate();
    const ctx = useEpiserver();
    const location = useLocation();
    const pathName = location.pathname;
    const containerRef = useRef<HTMLDivElement>(null);

    const [languages, setLanguages] = useState<Taxonomy.LanguageList>([]);

    useEffect(() => {
        let isCancelled = false;

        const loadLanguages = async () => {
            const website = await repo.getCurrentWebsite();
            if (isCancelled) return;

            if (website) {
                setLanguages(website.languages);
            } else {
                const response = await axios.get('/api/episerver/v3.0/site');
                setLanguages((response.data[0] as Website).languages);
            }
        };

        loadLanguages();

        return () => {
            isCancelled = true;
        };
    }, [repo]);

    const createLanguageList = () => {
        const supportedLanguages = languages.filter((lang) => {
            switch (lang.name) {
                case LanguageTag.NORWEGIAN:
                    return true;
                case LanguageTag.SAMI:
                    return props?.settings?.useSamisk?.value;
                case LanguageTag.ENGLISH:
                    return props?.settings?.useEnglish?.value;
                default:
                    return false;
            }
        });

        return supportedLanguages.sort((a, b) => {
            if (a.name === LanguageTag.NORWEGIAN) return -1;
            if (b.name === LanguageTag.NORWEGIAN) return 1;
            return 0;
        });
    };

    const availableLanguages = createLanguageList();

    const handleLanguageSelect = (language: Taxonomy.Language) => {
        dispatch && dispatch({ type: 'OptiContentCloud/SetState', currentLanguage: language.name });

        if (pathName) {
            repo.getByRoute(pathName).then((currentContent) => {
                if (currentContent.existingLanguages && currentContent.existingLanguages.length > 0) {
                    const currentContentInNewLanguageList = currentContent.existingLanguages.filter(
                        (lang) => lang.name === language.name
                    );
                    if (currentContentInNewLanguageList && currentContentInNewLanguageList.length > 0) {
                        const currentContentInNewLanguageUrl = new URL(currentContentInNewLanguageList[0].link);
                        navigate(ctx.getSpaRoute(currentContentInNewLanguageUrl.pathname));
                    } else {
                        // do this to set show norwegian page if page has not avaiable language
                        const norwegianContent = currentContent.existingLanguages.filter((lang) => lang.name === 'no');
                        if (norwegianContent && norwegianContent.length > 0) {
                            const norwegianContentLanguageUrl = new URL(norwegianContent[0].link);
                            navigate(ctx.getSpaRoute(norwegianContentLanguageUrl.pathname));
                        }
                    }
                }
            });
        } else if (language.urlSegment) navigate(ctx.getSpaRoute(language.urlSegment));

        setIsOpenLanguageSelector(false);
        clickedOnLink && clickedOnLink();
    };

    if (availableLanguages.length === 0 || !props.settings?.useLanguageSelector?.value) {
        return null;
    }

    if (availableLanguages.length === 0 || !props.settings?.useLanguageSelector?.value) {
        return null;
    }

    return (
        <div className="language-selection">
            <div className="languages" ref={containerRef}>
                {availableLanguages.map((lang, index) => (
                    <Link
                        key={`lang-${lang.name}`}
                        ref={index === 0 ? languageFieldRef : undefined}
                        to="#"
                        onClick={() => handleLanguageSelect(lang)}
                        lang={lang.urlSegment}
                        tabIndex={isOpenLanguageSelector ? 0 : -1}
                        className={`language-link ${lang.name === currentLanguage ? 'active' : ''} ${isMobile ? 'mobile' : ''}`}
                    >
                        {lang.name === currentLanguage && <Icon icon={CheckIcon} iconSize="medium" />}
                        {languageNames[lang.name]}
                    </Link>
                ))}
            </div>
        </div>
    );
};

export const ConnectedLanguageSelector = connect((state: State.CmsAppState) => state.OptiContentCloud || {})(
    DefaultLanguageSelector
);

export const LanguageSelector = (props: LanguageSelectorProps) => {
    const ctx = useEpiserver();
    if (ctx.isServerSideRendering()) return <DefaultLanguageSelector {...props} />;
    return <ConnectedLanguageSelector {...props} />;
};

export default LanguageSelector;
