import IEpiserverContext from '@episerver/spa-core/dist/Core/IEpiserverContext';
import IContent from '@episerver/spa-core/dist/Models/IContent';

export function isHoyesterettPage(currentContent: IContent | undefined): boolean {
    if (!currentContent || !currentContent.contentType) {
        return false;
    }

    const hoyesterettContentTypes = [
        'OldDomainPage',
        'OldBerammingSokPage',
        'ArticlePage',
        'DummyPage',
        'SiteMapPage',
        'TagPage',
        'OldSearchPage',
        'TransportPage',
    ];

    return hoyesterettContentTypes.some((type) => currentContent?.contentType?.includes(type));
}

export function shouldRenderEmptyLayout(currentContent: IContent, ctx: IEpiserverContext) {
    return !currentContent && !ctx.isServerSideRendering();
}
