import { useState, useEffect } from 'react';
import { Taxonomy, useIContentRepository } from '@episerver/spa-core';
import { useLocation } from 'react-router';

export function useCurrentContent(): {
    currentContent: Taxonomy.IContent | undefined;
    pathName: string;
} {
    const [currentContent, setCurrentContent] = useState<Taxonomy.IContent | undefined>();
    const repo = useIContentRepository();
    const location = useLocation();
    const pathName = location.pathname;

    useEffect(() => {
        const pathName = location.pathname;

        if (pathName) {
            repo.getByRoute(pathName).then((iContent) => {
                setCurrentContent(iContent);
            });
        }
    }, [location.pathname, repo]);

    return { currentContent, pathName };
}

export default useCurrentContent;
