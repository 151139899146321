import { ContentDelivery } from '@episerver/spa-core';
import Link from '../../../../Components/Shared/Link/link';

type LinkType = {
    href: string;
    text: string;
};

type MenuItemLinkProps = {
    links: ContentDelivery.LinkListProperty;
    handleOnClick: () => void;
};

export const MenuItem = ({ links, handleOnClick }: MenuItemLinkProps) => {
    if (!links?.value) {
        return null;
    }

    const linkList: LinkType[] = links.value.map((link: ContentDelivery.LinkProperty) => ({
        href: link.href,
        text: link.text || link.title,
    }));

    if (linkList.length === 1) {
        return (
            <Link className="main-link" onClick={handleOnClick} href={linkList[0].href}>
                {linkList[0].text}
            </Link>
        );
    }

    return (
        <ul className="tu-link-section">
            {linkList.map((link, index) => (
                <li key={`${link.href}-${index}`}>
                    <Link
                        className={index === 0 ? 'main-link' : 'secondary-link'}
                        onClick={handleOnClick}
                        href={link.href}
                    >
                        {link.text}
                    </Link>
                </li>
            ))}
        </ul>
    );
};

export default MenuItem;
