import LayoutSettings from 'app/Models/Content/LayoutSettingsData';
import { SiteType } from '../../../Models/Enums/SiteType';
import FooterDomstolno from './FooterDomstolno';
import FooterTU from './FooterTilsynsutvalget';
import { memo } from 'react';

interface FooterProps {
    settings: LayoutSettings;
}

const Footer = ({ settings }: FooterProps) => {
    const siteTypeValue = settings?.siteType?.value;
    const languageName = settings?.language?.name;

    if (siteTypeValue === SiteType.Tilsynsutvalget) {
        return <FooterTU aboutWebsite={settings.aboutTheWebsiteBlock} language={languageName} />;
    }

    return <FooterDomstolno settings={settings} />;
};

const areEqual = (prevProps: FooterProps, nextProps: FooterProps) => {
    const prevSettings = prevProps.settings;
    const nextSettings = nextProps.settings;

    return (
        prevSettings?.siteType?.value === nextSettings?.siteType?.value &&
        prevSettings?.language?.name === nextSettings?.language?.name &&
        prevSettings?.changed === nextSettings?.changed
    );
};

const MemoizedFooter = memo(Footer, areEqual);

const FooterWrapper = ({ settings }: { settings: LayoutSettings }) => {
    return <MemoizedFooter settings={settings} />;
};

export default FooterWrapper;
