import { Helmet } from 'react-helmet-async';

export const EmptyLayout = () => {
    return (
        <div className="mosey-layout">
            <Helmet>
                <title>Norges domstoler</title>
            </Helmet>
        </div>
    );
};

export default EmptyLayout;
