import {
    FacebookIcon,
    FlickrIcon,
    FooterListHeader,
    FooterSocialsGroup,
    FooterSocialsList,
    Icon,
    InstagramIcon,
    Link,
    LinkedInIcon,
    SvgIcon,
    XIcon,
} from '@norges-domstoler/dds-components';
import SocialMediaBlockData from '../../../Models/SocialMediaBlockData';

const hasNoSocialMediaLinks = (socialMediaBlock: SocialMediaBlockData) => {
    const { facebook, twitter, instagram, linkedIn, flickr } = socialMediaBlock || {};
    return !(facebook?.value || twitter?.value || instagram?.value || linkedIn?.value || flickr?.value);
};

export const FooterSocialMediaSection = ({ socialMediaBlock }: { socialMediaBlock: SocialMediaBlockData }) => {
    if (hasNoSocialMediaLinks(socialMediaBlock)) {
        return null;
    }

    const renderSocialMediaLink = (href: string | undefined, title: string, IconComponent: SvgIcon) => {
        if (!href) return null;
        return (
            <li>
                <Link href={href} htmlProps={{ title }}>
                    <Icon icon={IconComponent} />
                </Link>
            </li>
        );
    };

    return (
        <FooterSocialsGroup>
            <FooterListHeader>{socialMediaBlock?.header?.value}</FooterListHeader>
            <FooterSocialsList>
                {renderSocialMediaLink(socialMediaBlock?.facebook?.value, 'Facebook', FacebookIcon)}
                {renderSocialMediaLink(socialMediaBlock?.twitter?.value, 'X', XIcon)}
                {renderSocialMediaLink(socialMediaBlock?.instagram?.value, 'Instagram', InstagramIcon)}
                {renderSocialMediaLink(socialMediaBlock?.linkedIn?.value, 'LinkedIn', LinkedInIcon)}
                {renderSocialMediaLink(socialMediaBlock?.flickr?.value, 'Flickr', FlickrIcon)}
            </FooterSocialsList>
        </FooterSocialsGroup>
    );
};

export default FooterSocialMediaSection;
