import { Button } from '@norges-domstoler/dds-components';
import { ArrowLeftIcon, CloseIcon, LanguageIcon, MenuIcon, SearchIcon } from '@norges-domstoler/dds-components';
import { languageNames } from '../../../../../Models/Constants/LanguageTypes';
import { languageDropdownMenuId, LanguageSearchType, MenuType, searchDropdownMenuId } from './NavigationSection';
import { LayoutSettingsType } from '../../Shared/types';
import { General, Header, useTranslation } from 'app/hooks/useTranslations';
import DropdownWrapper from './DropdownWrapper';
import LanguageSelector from '../../Shared/LanguageSelector/LanguageSelector';
import SearchBoxHeader from './SearchBoxHeader';
import { DomstolMenuIdType } from 'app/Components/Layout/Hooks/useLayoutSettings';
import { useRef } from 'react';

export const languageButtonId = 'language-button';
export const searchButtonId = 'search-button';
export const hamburgerButtonId = 'hamburger-button';

type NavButtonAndDropdownGroupProps = {
    activeMenu: string | undefined;
    isOpenMobileMenu: boolean;
    currentLanguage: string;
    toggleMobileMenu: () => void;
    toggleLanguageSearchButtons: any;
    closeAllMenus: any;
    path: string;
    setActiveMenu: React.Dispatch<React.SetStateAction<LanguageSearchType | DomstolMenuIdType>>;
} & LayoutSettingsType;

export const NavButtonAndDropdownGroup = ({
    activeMenu,
    isOpenMobileMenu,
    currentLanguage,
    settings,
    toggleMobileMenu,
    closeAllMenus,
    path,
    toggleLanguageSearchButtons,
    setActiveMenu,
    ...props
}: NavButtonAndDropdownGroupProps) => {
    const { t } = useTranslation();

    const searchFieldRef = useRef<HTMLInputElement>(null);
    const languageFieldRef = useRef<HTMLAnchorElement>(null);

    const isLanguageMenuActive = activeMenu === MenuType.Language;
    const isSearchMenuActive = activeMenu === MenuType.Search;

    const closeButtonText = t(`${General}.Close`, currentLanguage);
    const searchButtonOpenText = t(`${General}.Search`, currentLanguage);
    const languageBackText = t(`${General}.Back`, currentLanguage);
    const mainMenuText = t(`${Header}.MainMenu`, currentLanguage);

    const languageButtonIcon = isLanguageMenuActive ? (isOpenMobileMenu ? ArrowLeftIcon : CloseIcon) : LanguageIcon;

    const searchButtonIcon = isSearchMenuActive ? CloseIcon : SearchIcon;

    const buttonText = isLanguageMenuActive
        ? isOpenMobileMenu
            ? languageBackText
            : closeButtonText
        : languageNames[currentLanguage];

    const toggleSearchLanguageMenu = (menuType: LanguageSearchType, event?: React.MouseEvent<HTMLButtonElement>) => {
        if (activeMenu === menuType) {
            setActiveMenu(MenuType.ClosedMenu);
        } else {
            setActiveMenu(menuType);
            if (event) {
                setTimeout(() => {
                    if (menuType === MenuType.Language && languageFieldRef.current) {
                        languageFieldRef.current.focus();
                    } else if (menuType === MenuType.Search && searchFieldRef.current) {
                        searchFieldRef.current.focus();
                    }
                }, 50);
            }
        }
    };

    const handleKeyDownWhenTabbing = (event: React.KeyboardEvent<HTMLButtonElement>) => {
        if (event.key === 'Tab') {
            closeAllMenus();
        }
    };

    const buttonHtmlProps = {
        onKeyDown: handleKeyDownWhenTabbing,
        onKeyUp: handleKeyDownWhenTabbing,
    };

    return (
        <ul>
            {settings?.useLanguageSelector?.value && (
                <li
                    className={`language-menu-container ${isOpenMobileMenu ? 'open-mobile' : ''} ${isLanguageMenuActive ? 'language-selection-open' : ''}`}
                >
                    <Button
                        className="nav-icon-buttons menu-link-button-hover"
                        id={languageButtonId}
                        purpose="tertiary"
                        aria-expanded={isLanguageMenuActive}
                        aria-controls="header-language-box-wrapper"
                        onClick={(event) => toggleSearchLanguageMenu(MenuType.Language, event)}
                        icon={languageButtonIcon}
                        htmlProps={buttonHtmlProps}
                        iconPosition={isOpenMobileMenu ? 'left' : 'right'}
                    >
                        {buttonText}
                    </Button>
                    <DropdownWrapper id={languageDropdownMenuId} isActive={activeMenu === MenuType.Language}>
                        <LanguageSelector
                            settings={settings}
                            isOpenLanguageSelector={activeMenu === MenuType.Language}
                            setIsOpenLanguageSelector={(isOpen: boolean) =>
                                toggleLanguageSearchButtons(MenuType.Language, isOpen)
                            }
                            languageFieldRef={languageFieldRef}
                            clickedOnLink={closeAllMenus}
                            {...props}
                        />
                    </DropdownWrapper>
                </li>
            )}

            {settings?.searchPageUrl?.value && !isOpenMobileMenu && (
                <li>
                    <Button
                        className="nav-icon-buttons menu-link-button-hover"
                        id={searchButtonId}
                        purpose="tertiary"
                        aria-expanded={isSearchMenuActive}
                        aria-controls="header-search-box-wrapper"
                        onClick={(event) => toggleSearchLanguageMenu(MenuType.Search, event)}
                        icon={searchButtonIcon}
                        iconPosition="right"
                        htmlProps={buttonHtmlProps}
                    >
                        {isSearchMenuActive ? closeButtonText : searchButtonOpenText}
                    </Button>
                    <DropdownWrapper id={searchDropdownMenuId} isActive={activeMenu === MenuType.Search}>
                        <SearchBoxHeader
                            isOpenSearch={activeMenu === MenuType.Search}
                            setIsOpenSearch={(isOpen: boolean) => toggleLanguageSearchButtons(MenuType.Search, isOpen)}
                            searchPath={settings?.searchPageUrl?.value?.href}
                            language={currentLanguage}
                            path={path}
                            searchFieldRef={searchFieldRef}
                        />
                    </DropdownWrapper>
                </li>
            )}

            <li className="burger-menu-container">
                <Button
                    id={hamburgerButtonId}
                    purpose="tertiary"
                    aria-label="Main Menu"
                    onClick={toggleMobileMenu}
                    icon={isOpenMobileMenu ? CloseIcon : MenuIcon}
                    iconPosition="right"
                >
                    {isOpenMobileMenu ? closeButtonText : mainMenuText}
                </Button>
            </li>
        </ul>
    );
};

export default NavButtonAndDropdownGroup;
