import { useEffect, useRef, useState } from 'react';
import { DomstolMenuIdType } from '../../../Hooks/useLayoutSettings';
import store, { AppActionTypes } from '../../../../../store';
import { hamburgerButtonId, languageButtonId, NavButtonAndDropdownGroup, searchButtonId } from './NavButtonGroup';
import MenuList from './MenuList';
import { HeaderProps } from '../../Shared/types';
import { Header, useTranslation } from 'app/hooks/useTranslations';
import { navigationMenuSetup } from '../../../topMenu';
import { logoId } from '../Logo/Logo';

export enum MenuType {
    Language = 'language',
    Search = 'search',
    ClosedMenu = 'closedMenu',
}

export type LanguageSearchType = typeof MenuType.Language | typeof MenuType.Search | typeof MenuType.ClosedMenu;
export const searchDropdownMenuId = 'search-dropdown-menu';
export const languageDropdownMenuId = 'langauge-dropdown-menu';

export const NavigationSection = ({ settings, currentLanguage, path, ...props }: HeaderProps) => {
    const { t } = useTranslation();

    const mainMenuText = t(`${Header}.MainMenu`, currentLanguage);

    const menuItemsRef = useRef<HTMLUListElement>(null);

    const [activeMenu, setActiveMenu] = useState<DomstolMenuIdType | LanguageSearchType>(MenuType.ClosedMenu);
    const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false);

    const toggleMobileMenu = () => {
        setIsOpenMobileMenu((prev) => !prev);
        setActiveMenu(MenuType.ClosedMenu);
        store.dispatch({ type: AppActionTypes.TOGGLE_MENU_MOBILE, payload: !isOpenMobileMenu });
    };

    const toggleLanguageSearchButtons = (type: LanguageSearchType, isOpen: boolean) => {
        setActiveMenu(isOpen ? type : MenuType.ClosedMenu);
    };

    const closeAllMenus = () => {
        setActiveMenu(MenuType.ClosedMenu);
        setIsOpenMobileMenu(false);
        store.dispatch({ type: AppActionTypes.TOGGLE_MENU_MOBILE, payload: false });
    };

    const anchorClickHandler = (menu: DomstolMenuIdType) => {
        menu === activeMenu ? setActiveMenu(MenuType.ClosedMenu) : setActiveMenu(menu);
    };

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            const target = event.target as Element;
            const clickedOnLanguageButton = target.closest(`#${languageButtonId}`);
            const clickedOnSearchButton = target.closest(`#${searchButtonId}`);
            const clickedOnHamburgerButton = target.closest(`#${hamburgerButtonId}`);

            if (clickedOnLanguageButton || clickedOnSearchButton || clickedOnHamburgerButton) {
                return;
            }

            if (isMenuItem(target) && isSearchContainer(target) && isLanguageContainer(target)) {
                closeAllMenus();
            }

            if (isLogoContainer(target)) {
                closeAllMenus();
            }
        };

        const isLogoContainer = (target: Element) => {
            return target.closest(`#${logoId}`);
        };

        const isMenuItem = (target: Node) => {
            return menuItemsRef.current && !menuItemsRef.current.contains(target);
        };

        const isSearchContainer = (target: Node) => {
            const searchDropdown = document.getElementById(searchDropdownMenuId);
            return searchDropdown && !searchDropdown.contains(target);
        };

        const isLanguageContainer = (target: Node) => {
            const languageDropdown = document.getElementById(languageDropdownMenuId);
            return languageDropdown && !languageDropdown.contains(target);
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        navigationMenuSetup();
    }, [settings, currentLanguage]);

    return (
        <>
            <nav className="nav-container" aria-label={mainMenuText}>
                <NavButtonAndDropdownGroup
                    activeMenu={activeMenu}
                    isOpenMobileMenu={isOpenMobileMenu}
                    currentLanguage={currentLanguage}
                    toggleLanguageSearchButtons={toggleLanguageSearchButtons}
                    setActiveMenu={setActiveMenu}
                    toggleMobileMenu={toggleMobileMenu}
                    settings={settings}
                    closeAllMenus={closeAllMenus}
                    path={path}
                    {...props}
                />
                <MenuList
                    isOpenMobileMenu={isOpenMobileMenu}
                    menuItemsRef={menuItemsRef}
                    settings={settings}
                    activeMenu={activeMenu}
                    handleClickOnLink={closeAllMenus}
                    anchorClickHandler={anchorClickHandler}
                />
            </nav>
        </>
    );
};

export default NavigationSection;
