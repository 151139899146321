import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { getSiteTypeName } from '../../../Models/Enums/SiteType';
import { RootLayoutProps } from '../RootLayoutProps';
import IEpiserverContext from '@episerver/spa-core/dist/Core/IEpiserverContext';
import { LayoutSettingsContext } from '../Hooks/useLayoutSettings';
import { Helmet } from 'react-helmet-async';
import store, { AppActionTypes } from '../../../store';
import HeaderWrapper from '../Header/HeaderWrapper';
import FooterWrapper from '../Footer/Footer';
import GlobalMessageWarning from './GlobalMessageWarning';
import './DefaultLayout.scss';

export const DefaultLayout = ({
    pathname,
    children,
    context,
    ...props
}: RootLayoutProps & {
    context: IEpiserverContext;
    children?: React.ReactNode;
    pathname?: string;
}) => {
    const { currentLanguage } = props;

    const topRef = useRef<HTMLSpanElement>(null);
    const mainRef = useRef<HTMLElement>(null);
    const [menuOpen, setMenuOpen] = useState(false);

    const layoutSettings = useContext(LayoutSettingsContext);
    const stableLayoutSettings = useMemo(() => layoutSettings, [layoutSettings]);

    const siteType = stableLayoutSettings?.siteType?.value;

    const clearBodyClasses = () => {
        document.body.classList.remove('supremeCourt', 'supervisoryCommittee');
    };

    useEffect(() => {
        if (!context.isServerSideRendering()) {
            clearBodyClasses();
        }
    }, [context]);

    useEffect(() => {
        topRef.current?.focus();
    }, [pathname]);

    useEffect(() => {
        const handleMainEvent = () => {
            mainRef.current?.focus();
        };

        if (!context.isServerSideRendering()) {
            document.body.addEventListener('mainEvent', handleMainEvent);
        }

        const unsubscribe = store.subscribe(() => {
            setMenuOpen(store.getState().isMenuOpen);
        });

        return () => {
            if (!context.isServerSideRendering()) {
                document.body.removeEventListener('mainEvent', handleMainEvent);
            }
            unsubscribe();
        };
    }, [context]);

    useEffect(() => {
        store.dispatch({ type: AppActionTypes.TOGGLE_MENU_MOBILE, payload: false });
    }, []);

    return (
        <div
            className={`mosey-layout ${getSiteTypeName(siteType)}`}
            style={context.isServerSideRendering() ? { visibility: 'hidden' } : {}}
        >
            <span className={menuOpen ? 'visually-hidden-main' : ''} ref={topRef} tabIndex={-1}></span>
            <Helmet htmlAttributes={{ lang: currentLanguage }}>
                <meta property="og:type" content="website" />
                <meta property="og:locale" content={currentLanguage ?? 'no'} />
            </Helmet>

            {!context.isServerSideRendering() && <HeaderWrapper settings={stableLayoutSettings} pathName={pathname} />}

            <GlobalMessageWarning globalWarning={stableLayoutSettings?.globalWarning} />

            <main id="main" ref={mainRef} className={menuOpen ? 'visually-hidden-main' : ''} tabIndex={-1}>
                {children}
            </main>

            {!context.isServerSideRendering() && !menuOpen && <FooterWrapper settings={stableLayoutSettings} />}
        </div>
    );
};

export default DefaultLayout;
