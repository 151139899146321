import { Footer as DDSFooter, FooterLeft, FooterLogo, Grid, GridChild } from '@norges-domstoler/dds-components';
import LayoutSettings from 'app/Models/Content/LayoutSettingsData';
import FooterSocialMediaSection from './FooterSocialMediaSection';
import FooterSection from './FooterSection';
import { LinkProperty } from '@episerver/spa-core/dist/Property';
import { LanguageTag } from 'app/Models/Constants/LanguageTypes';

export const FooterDomstolno = ({ settings }: { settings: LayoutSettings }) => {
    if (!settings) {
        return null;
    }

    const { socialMediaBlock, ourServicesBlock, contactBlock, aboutTheWebsiteBlock } = settings;

    const language = settings?.language?.name ?? LanguageTag.NORWEGIAN;

    const renderFooterSection = (header: string, links: (LinkProperty | undefined)[]) => (
        <FooterSection header={header} links={links.filter(Boolean) as LinkProperty[]} />
    );

    return (
        <DDSFooter>
            <Grid
                as="div"
                className="container page-container footer-max-width"
                rowGap={{ sm: 'var(--dds-spacing-x2-5)', xs: 'var(--dds-spacing-x2-5)' }}
            >
                <GridChild columnsOccupied={{ xl: '1/4', lg: '1/4', md: '1/4', sm: '1/-1', xs: '1/-1' }}>
                    <FooterLeft>
                        <FooterLogo src={`/StaticContent/logo/hovedlogo_negativ_${language}.svg`} hideBreakpoint="xs" />
                        <FooterSocialMediaSection socialMediaBlock={socialMediaBlock} />
                    </FooterLeft>
                </GridChild>

                {ourServicesBlock && (
                    <GridChild columnsOccupied={{ xl: '4/7', lg: '4/7', md: '4/7', sm: '1/-1', xs: '1/-1' }}>
                        {renderFooterSection(ourServicesBlock?.header.value, [
                            ourServicesBlock.aktorPortalenUrl?.value,
                            ourServicesBlock.pressetjenesterUrl?.value,
                            ourServicesBlock.whenGoesTrialUrl?.value,
                        ])}
                    </GridChild>
                )}

                {contactBlock && (
                    <GridChild columnsOccupied={{ xl: '7/10', lg: '7/10', md: '7/10', sm: '1/-1', xs: '1/-1' }}>
                        {renderFooterSection(contactBlock?.header.value, [
                            contactBlock.contactUsUrl?.value,
                            contactBlock.findCourtUrl?.value,
                        ])}
                    </GridChild>
                )}

                {aboutTheWebsiteBlock && (
                    <GridChild columnsOccupied={{ xl: '10/13', lg: '10/13', md: '10/13', sm: '1/-1', xs: '1/-1' }}>
                        {renderFooterSection(aboutTheWebsiteBlock?.header.value, [
                            aboutTheWebsiteBlock.availabilityUrl?.value,
                            aboutTheWebsiteBlock.privacyUrl?.value,
                        ])}
                    </GridChild>
                )}
            </Grid>
        </DDSFooter>
    );
};
export default FooterDomstolno;
