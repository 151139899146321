import axios from 'axios';
import React, { createContext, useEffect, useState } from 'react';

interface Localization {
    no: Record<string, string>;
    en: Record<string, string>;
    se: Record<string, string>;
}

const fallbackLocalization = {
    no: {},
    en: {},
    se: {},
};

export const LocalizationContext = createContext<Localization>(fallbackLocalization);

export const LocalizationProvider = ({ children }: { children: React.ReactNode }) => {
    const [localization, setLocalization] = useState<Localization | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchLocalization = async () => {
            try {
                const response = await axios.get('/jsl10n/localization/Domstolno');
                const localizationData = response.data;

                setLocalization({
                    no: localizationData.no.Domstolno.Resources,
                    en: localizationData.en.Domstolno.Resources,
                    se: localizationData.se.Domstolno.Resources,
                });
            } catch {
                setLocalization(fallbackLocalization);
            } finally {
                setLoading(false);
            }
        };

        fetchLocalization();
    }, []);

    if (loading) {
        return undefined;
    }

    return <LocalizationContext.Provider value={localization}>{children}</LocalizationContext.Provider>;
};

export default LocalizationProvider;
